export const loginUser = body =>
  fetch(`${process.env.REACT_APP_SERVER_URL}/auth/login`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(body),
  })

export const getUserIngo = access_token =>
  fetch(`${process.env.REACT_APP_SERVER_URL}/users/current`, {
    headers: {
      Authorization: 'Bearer ' + access_token,
    },
  })
