import { createAsyncThunk } from '@reduxjs/toolkit'
import { getUserIngo, loginUser } from '../../../../api/user'
import HttpStatus from '../../../../constants/http'
import { AlertMessages } from '../../../../constants/ui'

export const loginUserThunk = createAsyncThunk(
  'user/login',
  async ({ username, password }) => {
    const res = await loginUser({ username, password })
    if (res.status === HttpStatus.CREATED) {
      const { access_token } = await res.json()

      const data = await getUserIngo(access_token).then(res => res.json())
      return { access_token: 'Bearer ' + access_token, ...data }
    }
    if (res.status === HttpStatus.INTERNAL_SERVER_ERROR) {
      throw new Error(AlertMessages.SERVER_ERROR)
    }
    if (res.status === HttpStatus.NOT_AUTHORIZED) {
      throw new Error(AlertMessages.INVALID_LOGIN_CREDENTIALS)
    }
    if (res.status === HttpStatus.INTERNAL_SERVER_ERROR) {
      throw new Error(AlertMessages.SERVER_ERROR)
    }
  },
)

export const logoutUserThunk = createAsyncThunk('user/logout', async () => true)
