// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAccessToken } from '../../../redux/slices/user/selectors'
import { io } from 'socket.io-client/build/esm-debug'
import { resetMain } from '../../../redux/slices/progress/thunks'
import { setProgressStage, resetProgress } from '../../../redux/slices/progress'
import { getIsDownloadStarted } from '../../../redux/slices/progress/selectors'

const TIMEOUT = 3000

const WithSockets = ({ children }) => {
  const dispatch = useDispatch()
  const access_token = useSelector(getAccessToken)
  const isDownloadStart = useSelector(getIsDownloadStarted)
  const [error, setError] = useState()

  useEffect(() => {
    if (!access_token) {
      return
    }

    const socket = io(`${process.env.REACT_APP_SERVER_URL}/events`, {
      extraHeaders: {
        Authorization: access_token,
      },
    })
    socket.on('connect', () => {
      socket.on('processing-status', ({ stage, failed }) => {
        if (!isDownloadStart && stage && failed) {
          return
        }
        if (failed) {
          setError(`Build failed on stage "${stage}"!`)
          dispatch(resetProgress())
        }
        setError()
        console.log(stage)
        if (stage === 'finished') {
          if (isDownloadStart) {
            setTimeout(() => {
              dispatch(resetMain())
              alert('Download finished')
            }, TIMEOUT)
            return
          }
          if (!isDownloadStart) {
            return
          }
        }
        dispatch(setProgressStage({ stage }))
      })
    })
  }, [access_token, dispatch, isDownloadStart])

  useEffect(() => {
    if (error) {
      alert(error)
    }
  }, [error])

  return children
}

export default WithSockets
