import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { store } from './redux/store'
import { Provider } from 'react-redux'
import WithInterceptFetch from './components/HOCs/WithInterceptFetch'
import './styles/styles.scss'
import WithSockets from './components/HOCs/WithSockets'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <WithInterceptFetch>
          <WithSockets>
            <App />
          </WithSockets>
        </WithInterceptFetch>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)
