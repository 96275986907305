import React, { lazy } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useRoutes } from 'react-router-dom'
import { getAccessToken } from '../../redux/slices/user/selectors'

const SignIn = lazy(() => import('../../pages/SignIn'))

const AuthRoutes = () => {
  const routes = useRoutes([
    {
      path: 'sign-in',
      element: <SignIn />,
    },
    { path: '*', element: <Navigate to='/auth/sign-in' /> },
  ])

  const access_token = useSelector(getAccessToken)

  return !access_token ? routes : <Navigate to='/panel/main' />
}

export default AuthRoutes
