import { createSlice } from '@reduxjs/toolkit'
import { resetMain } from './thunks'
import * as R from 'ramda'

export const STATE_KEY = 'progress'

const initialState = {
  in_queue: {
    value: 'in_queue',
    displayName: 'In Queue',
    isPassed: false,
  },
  downloading: {
    value: 'downloading',
    displayName: 'Download',
    isPassed: false,
  },
  building_assets: {
    value: 'building_assets',
    displayName: 'Build',
    isPassed: false,
  },
  uploading_to_storage: {
    value: 'uploading_to_storage',
    displayName: 'Server',
    isPassed: false,
  },
  finished: {
    value: 'finished',
    displayName: 'Finished',
    isPassed: false,
  },
}

const progressSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {
    setProgressStage: (state, action) => {
      const { stage } = action.payload
      if (state[stage]) {
        if (stage !== 'in_queue' && !state.in_queue.isPassed) {
          let isGetStage = false
          const stages = Object.values(initialState).map(stageObj => {
            if (!isGetStage) {
              if (stageObj.value === stage) {
                isGetStage = true
              }
              return { ...stageObj, isPassed: true }
            }
            return stageObj
          })
          return R.indexBy(R.prop('value'), stages)
        }
        state[stage].isPassed = true
      }
    },
    resetProgress: () => initialState,
  },
  extraReducers: {
    [resetMain.fulfilled]: () => {
      return initialState
    },
  },
})

export const { setProgressStage, resetProgress } = progressSlice.actions

export const progressReducer = progressSlice.reducer
