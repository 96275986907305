import { createSlice } from '@reduxjs/toolkit'
import * as R from 'ramda'
import { fetchBooksThunk } from './thunks'
import {
  forceUpdateAssetsThunk,
  resetForceUpdate,
  resetMain,
} from '../progress/thunks'
import { getCurrentProcessThunk } from '../common/thunks'

export const STATE_KEY = 'books'

const initialState = {
  list: {},
  selected: {
    forceUpdate: [],
    main: [],
  },
}

const booksSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {
    addBookToDownload: state => {
      state.selected.main.push({})
    },
    addBookToForceUpdate: state => {
      state.selected.forceUpdate.push({})
    },
    updateSelectedBookToDownload: (state, action) => {
      const { index, book } = action.payload

      const copySelectedBooks = state.selected.main
      copySelectedBooks[index] = {
        ...book,
        selectedChapters: book.chapters,
      }

      state.selected.main = copySelectedBooks
    },
    updateSelectedBookForceUpdate: (state, action) => {
      const { index, book } = action.payload

      const copySelectedBooks = state.selected.forceUpdate
      copySelectedBooks[index] = book

      state.selected.forceUpdate = copySelectedBooks
    },
    updateSelectedBookChapters: (state, action) => {
      const { bookIndex, chapterIndex } = action.payload

      const copyUploadedBook = state.selected.main[bookIndex]

      const isLastSelectedChapter =
        copyUploadedBook.selectedChapters.length - 1 === chapterIndex

      if (isLastSelectedChapter) {
        copyUploadedBook.selectedChapters = copyUploadedBook.chapters.slice(
          0,
          chapterIndex,
        )
        state.selected.main[bookIndex] = copyUploadedBook
        return
      }
      copyUploadedBook.selectedChapters = copyUploadedBook.chapters.slice(
        0,
        chapterIndex + 1,
      )
      state.selected.main[bookIndex] = copyUploadedBook
    },
    deleteBookToDownload: (state, action) => {
      const { deletedIndex } = action.payload

      state.selected.main = state.selected.main.filter(
        (_, index) => deletedIndex !== index,
      )
    },
    deleteBookForceUpdate: (state, action) => {
      const { deletedIndex } = action.payload

      state.selected.forceUpdate = state.selected.forceUpdate.filter(
        (_, index) => deletedIndex !== index,
      )
    },
    clearSelectedToDownloadBooks: state => {
      state.selected.main = initialState.selected.main
    },
    clearSelectedForceUpdateBooks: state => {
      state.selected.forceUpdate = initialState.selected.forceUpdate
    },
  },
  extraReducers: {
    [fetchBooksThunk.fulfilled]: (state, action) => {
      state.list = R.indexBy(R.prop('id'), action.payload)
    },
    [resetMain.fulfilled]: state => {
      state.selected.main = initialState.selected.main
    },
    [resetForceUpdate.fulfilled]: state => {
      state.selected.forceUpdate = initialState.selected.forceUpdate
    },
    [forceUpdateAssetsThunk.fulfilled]: state => {
      state.selected.forceUpdate = initialState.selected.forceUpdate
      alert('Force update successfully')
    },
    [getCurrentProcessThunk.fulfilled]: (state, action) => {
      const { books } = action.payload

      state.selected.main = books.map(book => ({
        ...book,
        selectedChapters: book.chapters,
      }))
    },
  },
})

export const {
  addBookToDownload,
  addBookToForceUpdate,
  updateSelectedBookToDownload,
  updateSelectedBookForceUpdate,
  updateSelectedBookChapters,
  deleteBookToDownload,
  deleteBookForceUpdate,
  clearSelectedToDownloadBooks,
  clearSelectedForceUpdateBooks,
} = booksSlice.actions

export const booksReducer = booksSlice.reducer
