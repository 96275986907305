const isBetweenX = (clientX, xCoords) => {
	const { x1, x2 } = xCoords

	return clientX < x2 && clientX > x1
}

const isBetweenY = (clientY, yCoords) => {
	const { y1, y2 } = yCoords

	return clientY < y2 && clientY > y1
}

const isInside = (mouseCoords, areaCoords) => {
	const { clientX, clientY } = mouseCoords

	return isBetweenX(clientX, areaCoords) && isBetweenY(clientY, areaCoords)
}

export default isInside
