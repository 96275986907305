import { createSelector } from 'reselect'

const getState = state => state.progress

export const getProgressStagesList = createSelector(getState, state =>
  Object.values(state),
)

export const getIsDownloadStarted = createSelector(
  getState,
  state => state.in_queue.isPassed,
)
