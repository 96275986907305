import { createAsyncThunk } from '@reduxjs/toolkit'
import { getCurrentProcess } from '../../../../api/process'
import HttpStatus from '../../../../constants/http'
import { AlertMessages } from '../../../../constants/ui'

export const getCurrentProcessThunk = createAsyncThunk(
  'process/getCurrentProcess',
  async ({ access_token }) => {
    const res = await getCurrentProcess({ access_token })
    if (res.status === HttpStatus.OK) {
      const { request } = await res.json()

      return request
    }
    if (res.status === HttpStatus.INTERNAL_SERVER_ERROR) {
      throw new Error(AlertMessages.SERVER_ERROR)
    }

    if (res.status === HttpStatus.NOT_FOUND) {
      throw new Error(AlertMessages.NOT_FOUND)
    }
    if (res.status === HttpStatus.NOT_AUTHORIZED) {
      throw new Error(AlertMessages.INVALID_LOGIN_CREDENTIALS)
    }

    if (res.status === HttpStatus.INTERNAL_SERVER_ERROR) {
      throw new Error(AlertMessages.SERVER_ERROR)
    }
  },
)
