import { createSlice } from '@reduxjs/toolkit'
import { loginUserThunk, logoutUserThunk } from './thunks'

export const STATE_KEY = 'user'

const initialState = {
  access_token: '',
  userId: '',
  username: '',
  roles: [],
}

const userSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {},
  extraReducers: {
    [loginUserThunk.fulfilled]: (state, action) => {
      return action.payload
    },
    [logoutUserThunk.fulfilled]: () => {
      return initialState
    },
  },
})

export const { logout } = userSlice.actions

export const userReducer = userSlice.reducer
