import { createSlice } from '@reduxjs/toolkit'
import { AlertVariantType } from '../../../constants/ui'
import { loginUserThunk } from '../user/thunks'
import { fetchPreferencesThunk } from '../preferences/thunks'

export const STATE_KEY = 'ui'

const initialState = {
  alert: {},
}

const uiSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {
    hideAlert: state => {
      state.alert = {}
    },
  },
  extraReducers: {
    [loginUserThunk.rejected]: (state, action) => {
      state.alert = {
        variant: AlertVariantType.ERROR,
        text: action.error.message,
      }
    },
    [fetchPreferencesThunk.rejected]: (state, action) => {
      state.alert = {
        variant: AlertVariantType.ERROR,
        text: action.error.message,
      }
    },
  },
})

export const { hideAlert } = uiSlice.actions

export const uiReducer = uiSlice.reducer
