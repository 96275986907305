import * as R from 'ramda'
import { createSelector } from 'reselect'

const getState = state => state.ui

export const getAlert = createSelector(getState, state => state.alert)

export const getIsShowAlert = createSelector(
  getAlert,
  alert => !R.isEmpty(alert),
)
