import { createSelector } from 'reselect'
import { UserRoles } from '../../../../constants/user'

const getState = state => state.user

export const getAccessToken = createSelector(
  getState,
  state => state.access_token,
)

export const selectUserRoles = createSelector(getState, state => state.roles)

export const getIsUserAdmin = createSelector(selectUserRoles, roles =>
  roles.includes(UserRoles.ADMIN),
)

export const getIsUserManager = createSelector(selectUserRoles, roles =>
  roles.includes(UserRoles.MANAGER),
)

export const getUserName = createSelector(getState, state => state.username)
