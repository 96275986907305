import './styles.scss'

import React, { memo } from 'react'
// import { BsSearch } from 'react-icons/bs'
import { NavLink } from 'react-router-dom'
import { UserNavbarView } from './UserNavBarView'

const PanelHeader = () => {
  return (
    <header className='admin-panel__header-container'>
      <nav className='admin-panel__header'>
        <div className='admin-panel__header__nav'>
          <div className='admin-panel__links'>
            <NavLink className='admin-panel__link' to='/panel/main'>
              Main
            </NavLink>
            <NavLink className='admin-panel__link' to='/panel/force-update'>
              Force Update
            </NavLink>
          </div>
          {/* <BsSearch className='search-icon' /> */}
        </div>
        <div className='admin-panel_header-right'>
          <UserNavbarView />
        </div>
      </nav>
    </header>
  )
}

export default memo(PanelHeader)
