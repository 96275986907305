import React, { useCallback, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { getUserName } from '../../../../redux/slices/user/selectors'
import styles from './styles.module.scss'
import useTapOutside from '../../../../hooks/tapOutside.hook'
import { store } from '../../../../redux/store'
import { LOGOUT_ACTION } from '../../../../redux/rootReducer'

export const UserNavbarView = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const containerRef = useRef(null)
  const dropdownRef = useRef(null)

  const username = useSelector(getUserName)

  const toggleDropdown = useCallback(() => {
    setIsDropdownVisible(!isDropdownVisible)
  }, [isDropdownVisible])

  const logout = useCallback(() => {
    store.dispatch(LOGOUT_ACTION)
  }, [])

  const hideDropdown = useCallback(() => {
    setIsDropdownVisible(false)
  }, [])

  useTapOutside([containerRef, dropdownRef], hideDropdown)

  return (
    <div className={styles.container} ref={containerRef}>
      <div onClick={toggleDropdown} className={styles.username}>
        {username}
      </div>
      {isDropdownVisible && (
        <div className={styles.dropdown} ref={dropdownRef}>
          <div className={`${styles.dropdownItem} ${styles.userInfo}`}>
            <div className={styles.usernameLabel}>Username:</div>
            <div className={styles.usernameValue}>{username}</div>
          </div>
          <div className={styles.dropdownItem}>
            <div onClick={logout} className={styles.logout}>
              Logout
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
