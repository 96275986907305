import React from 'react'

import './styles.scss'

const LoadingSuspenseSpinner = () => (
  <div className='loading-suspense-spinner-container'>
    <div className='loading-suspense-spinner' />
  </div>
)

export default LoadingSuspenseSpinner
