export const ALERT_FADE_TIMEOUT = 3000

export const AlertMessages = Object.freeze({
  INVALID_LOGIN_CREDENTIALS: 'Invalid username or password',
  SERVER_ERROR: 'Server error',
  NOT_FOUND: '404 error',
})

export const AlertVariantType = Object.freeze({
  ERROR: 'error',
  SUCCESS: 'success',
})
