export const fetchPreferences = ({ access_token }) =>
  window.interceptFetch(
    `${process.env.REACT_APP_SERVER_URL}/preferences/options`,
    {
      method: 'GET',
      headers: {
        Authorization: access_token,
      },
    },
  )
