import React, { Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import AuthRoutes from './routes/Auth'
import LoadingSuspenseSpinner from './components/LoadingSuspenseSpinner'
import AdminRoutes from './routes/Admin'
import Alert from './components/UI/Alert'

function App() {
  return (
    <Suspense fallback={<LoadingSuspenseSpinner />}>
      <Routes>
        <Route path='panel/*' element={<AdminRoutes />} />
        <Route path='auth/*' element={<AuthRoutes />} />
        <Route path='*' element={<Navigate to='auth/sign-in' />} />
      </Routes>
      <Alert />
    </Suspense>
  )
}

export default App
