export const downloadAssets = ({ access_token, body }) =>
  window.interceptFetch(`${process.env.REACT_APP_SERVER_URL}/assets/download`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      Authorization: access_token,
    },
    body: JSON.stringify(body),
  })

export const forceUpdateAssets = ({ access_token, body }) =>
  window.interceptFetch(`${process.env.REACT_APP_SERVER_URL}/assets/clean`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      Authorization: access_token,
    },
    body: JSON.stringify(body),
  })

export const getCurrentProcess = ({ access_token }) =>
  window.interceptFetch(
    `${process.env.REACT_APP_SERVER_URL}/assets/download/current`,
    {
      headers: {
        Authorization: access_token,
      },
    },
  )
