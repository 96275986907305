import { combineReducers } from 'redux'
import { userReducer, STATE_KEY as USER_STATE_KEY } from './slices/user'
import { booksReducer, STATE_KEY as BOOKS_STATE_KEY } from './slices/books'
import { uiReducer, STATE_KEY as UI_STATE_KEY } from './slices/ui'
import {
  progressReducer,
  STATE_KEY as PROGRESS_STATE_KEY,
} from './slices/progress'
import {
  preferencesReducer,
  STATE_KEY as PREFERENCES_STATE_KEY,
} from './slices/preferences'

export const LOGOUT_ACTION = {
  type: 'USER_LOGOUT',
}

const appReducer = combineReducers({
  [USER_STATE_KEY]: userReducer,
  [UI_STATE_KEY]: uiReducer,
  [PREFERENCES_STATE_KEY]: preferencesReducer,
  [BOOKS_STATE_KEY]: booksReducer,
  [PROGRESS_STATE_KEY]: progressReducer,
})

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_ACTION.type) {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
