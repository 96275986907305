import { useDispatch } from 'react-redux'
import HttpStatus from '../../../constants/http'
import { logoutUserThunk } from '../../../redux/slices/user/thunks'

const WithInterceptFetch = ({ children }) => {
  const dispatch = useDispatch()

  window.interceptFetch = async (...args) => {
    const response = await fetch(...args)
    if (response.status === HttpStatus.NOT_AUTHORIZED) {
      dispatch(logoutUserThunk())
      return
    }

    return response
  }

  return children
}

export default WithInterceptFetch
