import { useEffect } from 'react'
import getAreaCoords from '../helpers/getAreaCoords'
import isInside from '../helpers/isInside'

const useTapOutside = (ref, handler) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      function handleClickOutsideElements(refs, mouseEvent) {
        const tapInside = refs
          .filter(r => r && r.current)
          .map(r => {
            const rect = r.current?.getBoundingClientRect() || {
              x: 0,
              y: 0,
              width: 0,
              height: 0,
            }
            const coords = getAreaCoords(rect)
            return isInside(mouseEvent, coords)
          })
          .reduce((result, current) => result || current, false)

        if (!tapInside && handler) {
          handler()
        }
      }

      if (Array.isArray(ref)) {
        handleClickOutsideElements(ref, event)
      } else {
        handleClickOutsideElements([ref], event)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, handler])
}

export default useTapOutside
