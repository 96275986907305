import React, { lazy, useEffect } from 'react'
import { Navigate, Outlet, useRoutes } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAccessToken } from '../../redux/slices/user/selectors'
import PanelHeader from '../../components/Panel/PanelHeader'
import styles from './styles.module.scss'
import { fetchPreferencesThunk } from '../../redux/slices/preferences/thunks'
import { fetchBooksThunk } from '../../redux/slices/books/thunks'
import { getCurrentProcessThunk } from '../../redux/slices/common/thunks'

const MainPagePanel = lazy(() => import('../../pages/Panel/Main'))
const ForceUpdatePagePanel = lazy(() => import('../../pages/Panel/ForceUpdate'))

const Panel = () => (
  <div className={styles.panel}>
    <PanelHeader />
    <Outlet />
  </div>
)

const AdminRoutes = () => {
  const dispatch = useDispatch()

  const routes = useRoutes([
    {
      path: '/',
      element: <Panel />,
      children: [
        {
          path: 'main',
          element: <MainPagePanel />,
        },
        {
          path: 'force-update',
          element: <ForceUpdatePagePanel />,
        },
      ],
    },
    {
      path: '*',
      element: <Navigate to='/panel/main' />,
    },
  ])

  const access_token = useSelector(getAccessToken)

  useEffect(() => {
    if (access_token) {
      dispatch(getCurrentProcessThunk({ access_token }))
      dispatch(fetchPreferencesThunk({ access_token }))
      dispatch(fetchBooksThunk({ access_token }))
    }
  }, [access_token, dispatch])

  return access_token ? routes : <Navigate to='/auth/sign-in' />
}

export default AdminRoutes
