import { createAsyncThunk } from '@reduxjs/toolkit'
import { downloadAssets, forceUpdateAssets } from '../../../../api/process'
import HttpStatus from '../../../../constants/http'

export const downloadAssetsThunk = createAsyncThunk(
  'progress/download',
  async ({ access_token, body }) => {
    await downloadAssets({ access_token, body })
    return true
  },
)

export const forceUpdateAssetsThunk = createAsyncThunk(
  'process/force-update',
  async ({ access_token, body }) => {
    const res = await forceUpdateAssets({ access_token, body })
    if (res.status === HttpStatus.CREATED) {
      return true
    }
    throw new Error('Force update error')
  },
)

export const resetMain = createAsyncThunk('app/resetMain', () => true)

export const resetForceUpdate = createAsyncThunk(
  'app/resetForceUpdate',
  () => true,
)
