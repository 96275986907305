import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAlert, getIsShowAlert } from '../../../redux/slices/ui/selectors'
import classNames from 'classnames'
import styles from './styles.module.scss'
import { hideAlert } from '../../../redux/slices/ui'
import { ALERT_FADE_TIMEOUT } from '../../../constants/ui'

const Alert = () => {
  const dispatch = useDispatch()

  const isShowAlert = useSelector(getIsShowAlert)
  const { variant, text } = useSelector(getAlert)

  const handleAlertHide = useCallback(() => dispatch(hideAlert()), [dispatch])

  useEffect(() => {
    if (isShowAlert) {
      setTimeout(handleAlertHide, ALERT_FADE_TIMEOUT)
    }
  }, [handleAlertHide, isShowAlert])

  return (
    isShowAlert && (
      <div className={classNames(styles.alert, styles[variant])}>{text}</div>
    )
  )
}

export default Alert
