import { createSlice } from '@reduxjs/toolkit'
import { fetchPreferencesThunk } from './thunks'
import { resetMain } from '../progress/thunks'
import { getCurrentProcessThunk } from '../common/thunks'

export const STATE_KEY = 'preferences'

const initialState = {
  list: {
    branches: [],
    platforms: [],
    buckets: [],
  },
  selected: {
    branch: {},
    platform: '',
    bucket: {},
  },
  options: {
    urgent: false,
  },
}

const preferencesSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {
    selectBranch: (state, action) => {
      state.selected.branch = action.payload
    },
    selectPlatform: (state, action) => {
      state.selected.platform = action.payload
    },
    selectBucket: (state, action) => {
      state.selected.bucket = action.payload
    },
    updateTaskUrgency: (state, action) => {
      state.options.urgent = action.payload
    },
  },
  extraReducers: {
    [fetchPreferencesThunk.fulfilled]: (state, { payload }) => {
      state.list = payload
    },
    [resetMain.fulfilled]: state => {
      state.selected = initialState.selected
      state.options = initialState.options
    },
    [getCurrentProcessThunk.fulfilled]: (state, action) => {
      const { bucket, platform, branch } = action.payload

      state.selected = { bucket, platform, branch }
    },
  },
})

export const { selectBranch, selectPlatform, selectBucket, updateTaskUrgency } =
  preferencesSlice.actions

export const preferencesReducer = preferencesSlice.reducer
